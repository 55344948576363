<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img :src="require('@/assets/images/logo/logo-sm.png')" alt="logo" />

          <h2 class="brand-text text-primary ml-1">
            iq.kaeltepartner.net
          </h2>
        </b-link>

        <b-card-text class="mb-2">
          Your password has been changed.
        </b-card-text>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BImg,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
